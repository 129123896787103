@import '../../styles/variables/breakpoints';
@import '../../styles/variables/colors';
@import '../../styles/shared/app';
@import '../../styles/variables/fonts';

@mixin charts-body($theme) {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  .charts-card {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 20px;
    box-shadow: 0px 0px 5px get-color($theme, 'shadow');
    border-radius: 10px;
    width: 100%;
    background-color: get-color($theme, 'white');
    padding: 20px 0px;
  }

  .charts-column {
    display: flex;
    flex-direction: column;
  }

  span {
    color: inherit;
  }
  .chart-box {
    margin: 20px;
    display: flex;
    flex-direction: column;
    // flex: 1;
    width: 98%;
    height: 400px;
    justify-self: center;
    // width: 50%;
  }
  .chart-title {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-family: $default-font;
    font-size: 1.1rem;
    color: get-color($theme, 'gray-medium');
  }

  .title-page {
    margin-left: 15px;
    font-family: $default-font;
    font-size: 1.5rem;
    color: get-color($theme, 'gray');
  }
  .charts-row {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0px;
    // align-self: center;
    align-items: center;
  }
  .charts-grid {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    // grid-template-columns: repeat(auto-fill, 450px);
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    flex-direction: column;
    // width: 100%;
    // align-self: center;
    // align-content: center;
    align-items: flex-start;
    // border: 1.2px solid #c7c7c9;
  }
  .charts-report-row {
    margin-top: 20px;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    // flex: 1;
    // width: 100%;
    // align-self: center;
    align-items: flex-start;
  }

  .group-select {
    margin: 0px 15px;
    display: flex;
    // flex: 1;
    align-items: center;
  }

  .list {
    margin: 0px 15px;
    // min-height: 100vw;
    width: 200px;
    overflow: auto;
    // background-color: lightblue;
    @include large-screen {
      max-height: 150px;
    }
  }

  .border {
    display: flex;
    // width: 90%;
    position: relative;
    flex-direction: column;
    // padding: 0.1%;
    border-radius: 5px;
    border: 1.2px solid #c7c7c9;
  }

  .group-value {
    display: flex;
    // flex: 1;
    flex-direction: column;
    align-items: center;
    margin: 0px 5px;
    max-width: 100px;
  }
  .text-select {
    padding-right: 15px;
    font-family: $default-font;
    font-size: 1.1rem;
    text-align: left;
    color: get-color($theme, 'gray-medium');
  }
  .text-select-item {
    font-family: $default-font;
    font-size: 1.1rem;
    color: get-color($theme, 'gray-medium');
  }
  .text-item {
    font-family: $default-font;
    font-size: 1rem;
    text-align: center;
    color: get-color($theme, 'gray-medium');
  }
  .text-value {
    font-family: $default-font;
    font-size: 1.5rem;
    color: get-color($theme, 'gray-medium');
  }

  .subheader-table {
    // margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // margin-top: 15px;
  }
  .description-text {
    // padding: 0px 15px;
    // height: 50px;
    font-family: $default-font;
    font-size: 1rem;
    text-align: center;
    color: get-color($theme, 'gray');
    @include small-screen {
      font-size: 0.9rem;
    }
  }
  .sale-text {
    font-family: $default-font;
    color: get-color($theme, 'accent');
  }

  .group-sales {
    display: flex;
    flex-direction: row;
    padding: 5px;
    @include small-screen {
      margin-top: 10px;
    }
  }
  .checkbox-root {
    // background-color: lightcoral;
    color: get-color($theme, 'accent');
    padding: 0.1rem;
    margin-right: 0.5rem;
  }

  .text-description {
    // background-color: blue;
    flex-wrap: nowrap;
    text-align: left;
    font-family: $default-font;
    font-size: 0.7rem;
    color: get-color($theme, 'gray-medium');
    @include small-screen {
      font-size: 0.6rem;
    }
    @include large-screen {
      font-size: 1rem;
    }
  }
  .text-title {
    font-family: $default-font;
    font-size: 1.2rem;
    color: get-color($theme, 'accent');
  }

  .box {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid get-color($theme, 'accent');
    border-radius: 10px;
  }

  .date-switch {
    span {
      color: get-color($theme, "accent");
    }
  }
}

.app-container {
  &-light {
    .charts-body {
      @include charts-body('light');
    }
  }
  &-dark {
    .charts-body {
      @include charts-body('dark');
    }
  }
}
