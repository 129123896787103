@import '../../styles/variables/breakpoints';
@import '../../styles/shared/global';
@import '../../styles/variables/colors';

@mixin app-headerbar($theme) {
  display: flex;
  margin: 0;
  z-index: 2;
  min-width: 320px;
  .headerbar-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .headerbar-middle-content {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
}

.app-container {
  &-light {
    .headerbar {
      @include app-headerbar('light');
    }
  }
  &-dark {
    .headerbar {
      @include app-headerbar('dark');
    }
  }
}
