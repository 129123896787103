@import "../../../styles/variables/colors";
@import "../../../styles/variables/fonts";

@mixin register-animals($theme) {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  margin: 0px;
  // border-radius: 5px;
  // background: get-color($theme, "background");
  font-family: $default-font;
  padding: 10px;

  &-name {
    padding-top: 1rem;
    padding-left: 1rem;
    font-size: x-large;

    &-h1 {
      color: get-color($theme, "gray");
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 90%;
    height: 720px;
    background: get-color($theme, "background-card");
    background-color: #107c41;
    margin: 10px auto 0;
    border: 1px solid rgba(0, 0, 0, 0.274);
    border-radius: 5px;

    &-tabs {
      display: flex;

      &-tab {
        height: 25px;
        padding-top: 10px;
        padding-bottom: 5px;
        text-align: center;
        justify-content: center;
        width: 100%;
        background: get-color($theme, "white");
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.274);
        box-sizing: content-box;
        position: relative;
        outline: none;
        color: get-color($theme, "gray");

        &:not(:last-child) {
          border-right: 1px solid rgba(0, 0, 0, 0.274);
        }

        &-active-tabs {
          padding-top: 10px;
          padding-bottom: 5px;
          justify-content: center;
          text-align: center;
          color: get-color($theme, "accent");
          background: get-color($theme, "accent-35");
          border-bottom: 1px solid transparent;
          width: 100%;

          &::before {
            content: "";
            display: block;
            width: 200px;
          }
        }
      }
    }

    &-contents-tabs {
      flex-grow: 1;

      &-panel-config {
        width: 100%;
      }

      &-panel-content {
        // margin-left: 10px;
        width: 100%;
        height: 100%;

        &-excel-download {
          display: table-row;
          align-items: center;
          height: 100%;
          margin-left: 50px;
          width: 100px;

          &-link {
            color: inherit;
            text-decoration: none;

            &-icon {
              margin-bottom: 8px;
              color: #107c41;
            }
            &-label {
              font-size: small;
              color: get-color($theme, "gray-medium");
            }
          }
        }

        &-youtube {
          justify-content: center;
          align-items: left;

          &-link {
            display: flex;
            margin-bottom: 15px;
            color: get-color($theme, "gray-medium");

            &-icon {
              text-decoration: none;
              color: #ff4e45;
            }

            &-text {
              margin-top: 8px;
              font-size: larger;
              margin-left: 5px;
            }

            &:hover {
              cursor: pointer;
              transition: 0.4s;
              color: get-color($theme, "accent-70");
            }
          }
        }

        &-import {
          display: flex;

          &-input {
            &-file {
              display: none;
            }
            &-label {
              margin-left: 10px;
              margin-bottom: 15px;
              justify-content: center;
              align-items: center;
              text-align: center;
              float: left;
              line-height: 1.75;
              box-sizing: border-box;
              border: 1px solid grey;
              padding: 6px 16px;
              font-size: 0.875rem;
              font-weight: 500;
              letter-spacing: 0.02857em;
              text-transform: uppercase;
              border-radius: 4px;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              cursor: pointer;
              &:hover {
                background: rgba(0, 0, 0, 0.055);
                transition: 0.3s;
              }

              &-icon {
                float: right;
                margin-left: 5px;
                margin-top: 1px;
              }
            }
          }
        }

        &-history {
          display: inline-block;
          margin: 5px;
        }
      }

      &-content {
        background: white;
        padding: 20px;
        width: 100%;
        height: 100%;
        display: none;

        &-active-content {
          display: flex;
          margin: 10px;
          width: 100%;
        }
      }

      &-feedlotParams {
        width: 100%;
        margin-top: -6px;
        overflow-y: auto;
        height: 650px;
        padding-right: 20px;
      }
    }
  }
  .modal-body-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    // width: fit-content;
    margin-left: 80px;
    margin-right: 50px;
    // margin: 0px 50px 0px 50px;
    // margin-top: 150px;
    // overflow-y: auto;
  }
  .modal-body-box-content {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 10px;
  }
  &-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
  }

  &-modal {
    font-family: $default-font;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    &-title {
      display: flex;
      font-family: $default-font;
      color: get-color($theme, "gray");
      align-items: center;
      justify-content: left;
      &-errors {
        font-size: 13px;
      }
    }

    &-table {
      height: 100%;
      width: 100%;
    }
  }
  .text-button{
    color: get-color($theme, "white");
  }
}

.app-container {
  &-light {
    .register-animals {
      @include register-animals("light");
    }
  }
  &-dark {
    .register-animals {
      @include register-animals("dark");
    }
  }
}
