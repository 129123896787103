@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../../styles/shared/app';

@mixin popover($theme) {
  .box {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid get-color($theme, 'border-gray');
  }
}
.popover {
  &-light {
    @include popover('light');
  }
  &-dark {
    @include popover('dark');
  }
}
