@import "../../../styles/variables/colors";
@import "../../../styles/variables/breakpoints";

@mixin zootechnical-card-container($theme) {
  margin-bottom: 1rem;
  cursor: pointer;

  .zootechnical-card-body {
    display: flex;
    flex-direction: column;
    width: 220px;
    max-width: 220px;
    min-height: 230px;
    border: 1px solid get-color($theme, "border-gray");
    // box-shadow: 0px 0px 4px get-color($theme, 'shadow');
    border-radius: 10px;
    margin: 0.3rem;
    padding: 0.7rem 0.5rem;
    justify-content: center;
    text-align: center;
    &:hover {
      box-shadow: 0px 0px 5px get-color($theme, "shadow");
    }
  }

  .zootechnical-card-title {
    color: get-color($theme, "gray-medium");
    font-size: 1.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .zootechnical-card-content-container {
    display: flex;
    flex: 1;
    align-items: center;
    height: 100%;
    margin-top: -1rem;

    .zootechnical-card-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .caption {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        color: get-color($theme, "gray-medium");
        font-size: 0.9rem;
        margin: 1rem 0 3px 0;
        &-multiline {
          @extend .caption;
          min-height: 1.8rem;
        }

        .text-lowercase {
          text-transform: lowercase;
        }
      }
      .value {
        font-size: 1.5rem;
        margin: 0.5rem;
        color: get-color($theme, "gray-medium");
      }

      .value-positive {
        color: #0e7108;
      }

      .value-negative {
        color: #d21111;
      }
    }
  }

  .text-select {
    color: get-color($theme, "gray-medium");
  }
  .zootechnical-card-footer {
    font-size: 0.9rem;
    text-align: center;
    .item {
      color: get-color($theme, "gray-medium");
      margin-bottom: 3px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.zootechnical-card-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-evenly;
}

.card-footer-custom {
  justify-content: left;
}

.card-footer-text-custom {
  text-align: left;
}
#lots-sort-select {
  margin: 0 1rem;
  width: 8rem;
  @include small-screen {
    margin: 0 0.5rem 0 0.45rem;
    max-width: 6.7rem;
  }
}

#lots-page-select {
  margin: 0rem;
  width: 6.9rem;
  @include small-screen {
    max-width: 6.3rem;
  }
}

.app-container {
  &-light {
    .zootechnical-card-container {
      @include zootechnical-card-container("light");
    }
  }
  &-dark {
    .zootechnical-card-container {
      @include zootechnical-card-container("dark");
    }
  }
}
