@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../../styles/shared/app';

@mixin scatter-chart($theme) {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 325px;
  // width: 50%;

  .tooltip-container {
    background-color: get-color($theme, 'ghost-white');
    border: 1px solid get-color($theme, 'gray');
    border-radius: 5px;
    padding: 5px;
  }

  .chart-responsive-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .chart-value-text {
    font-weight: bold;
    font-size: 1rem;
    font-family: $default-font;
  }
  
  .custom-legend {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    font-weight: bold;
    .firstSale{
      color: get-color($theme, 'accent')
    }
    .secondSale{
      color: get-color($theme, 'accent-70')
    }
    .thirdSale{
      color: get-color($theme, 'accent-35')
    }
    .small-txt {
      display: flex;
      justify-content: center;
      font-size: 0.7rem;
    }
    .legend-item
    .firstSale 
    .secondSale
    .thirdSale {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
.app-container {
  &-light {
    .scatter-chart {
      @include scatter-chart('light');
    }
  }
  &-dark {
    .scatter-chart {
      @include scatter-chart('dark');
    }
  }
}
