@import "../../styles/variables/fonts";
@import "../../styles/variables/colors";
@import "../../styles/variables/breakpoints";

$icon-container-width: 100px;

@mixin pon-calendar-details-body($theme) {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0px 0px 5px get-color($theme, 'shadow');
  border-radius: 10px;
  background-color: get-color($theme, 'white-bg');
  margin: 1rem 1rem;
  padding: 1rem;

  .title-container {
    align-self: center;

    p {
      font-family: $default-font;
      font-size: 1.8rem;
      padding-top: 12px;
      padding-left: $icon-container-width + 20px;
      color: get-color($theme, "gray");
      @include small-screen {
        font-size: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    small {
      font-family: $default-font;
      font-size: 0.8rem;
      padding-left: $icon-container-width + 50px;
      color: get-color($theme, "gray-medium");
      @include small-screen {
        font-size: 0.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .card-traffic-light-container {
    .MuiButton-root {
      @include small-screen {
          width: 150px;
      }
      @include medium-screen {
          width: 300px;
      }
      @include large-screen {
          width: 300px;
      }
    }
  }

  .sales-buttons-line {
    align-self: flex-end;
    margin-bottom: 1rem;
  }

  .pon-calendar {
    .sales {
      display: flex;
      flex-direction: column;

      .MuiDivider-root {
        margin: 1rem;
      }
  
      .scaled-lots-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        .traffic-red-light {
          margin-right: 0.5rem;
        }
        .button-title {
          font-weight: bold;
        }
        .none-lot-txt {
          display: flex;
          align-items: center;
        }
        .scaled-lots {
          display: grid;
          background-color: rgba(255, 0, 0, 0.5);
          margin: 0.5rem;
          small {
            font-size: 0.7rem;
          }
        }
      }
  
      .next-sale-lots-container {
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        .traffic-yellow-light {
          margin: 0.5rem;
        }
        .button-title {
          font-weight: bold;
        }
        .none-lot-txt {
          display: flex;
          align-items: center;
        }
        .next-sale-lots {
          display: grid;
          background-color: rgba(255, 255, 0, 0.5);
          margin: 0.5rem;
          small {
            font-size: 0.7rem;
          }
        }
    }
  
      .initial-lots-container {
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;  
        margin-bottom: 1rem;
        .traffic-green-light {
          margin: 0.5rem;
        }
        .button-title {
          font-weight: bold;
        }
        .none-lot-txt {
          display: flex;
          align-items: center;
        }
        .initial-lots {
          display: grid;
          background-color: rgba(0, 128, 0, 0.5);
          margin: 0.5rem;
          small {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

.app-container {
  &-light {
    .pon-calendar-details-body {
      @include pon-calendar-details-body('light');
    }
  }
  &-dark {
    .pon-calendar-details-body {
      @include pon-calendar-details-body('dark');
    }
  }
}