@import '../../../../styles/variables/breakpoints';
@import '../../../../styles/variables/colors';
@import '../../../../styles/shared/app';

@mixin feedlot-params-body($theme) {
  // display: flex;
  // flex: 1;
  // flex-wrap: wrap;
  // width: 100%;
  // height: auto;

  .form-body {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    // width: 100%;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .input-margin {
      margin-bottom: 1rem;
    }

    .input-margin-right {
      margin-right: 1rem;
    }
  }
}

.app-container {
  &-light {
    .feedlot-params-body {
      @include feedlot-params-body('light');
    }
  }
  &-dark {
    .feedlot-params-body {
      @include feedlot-params-body('dark');
    }
  }
}
