@import '../../styles/variables/breakpoints';
@import '../../styles/variables/colors';
@import '../../styles/shared/app';
@import '../../styles/variables/fonts';

@mixin lot-body($theme) {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 35px;  
  box-shadow: 0px 0px 5px get-color($theme, "shadow");
  border-radius: 10px;
  width: 100%;
  background-color:  get-color($theme, "white");

  .lot-row {
    margin-top: 20px;
    justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    // width: 100%;
    // align-self: center;
    align-items: center;
    // align-items: center;
  }
  .lot-container {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  .group {
    display: flex;
    flex-direction: row;
    // flex: 1;
  }
  .group-select {
    margin: 0px 15px;
    display: flex;
    // flex: 1;
    align-items: center;
  }
  .text-select {
    padding-right: 15px;
    font-family: $default-font;
    font-size: 1.1rem;
    text-align: left;
    color: get-color($theme, 'gray-medium');
  }
  .text-select-item {
    font-family: $default-font;
    font-size: 1.1rem;
    color: get-color($theme, 'gray-medium');
  }
}

.app-container {
  &-light {
    .lot-body {
      @include lot-body('light');
    }
  }
  &-dark {
    .lot-body {
      @include lot-body('dark');
    }
  }
}
