@import '../../styles/variables/colors';

@mixin popover($theme) {
  display: flex;
  text-align: center;

  button {
    color: get-color($theme, 'accent');
  }
}

.no-notifications {
  padding: 1rem;
}

.popover {
  &-light {
    @include popover('light');
  }
  &-dark {
    @include popover('dark');
  }
}
