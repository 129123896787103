@import '../../styles/variables/breakpoints';
@import '../../styles/variables/colors';
@import '../../styles/shared/app';
@import '../../styles/variables/fonts';

@mixin modal-body($theme) {
    position:fixed;
    top:0;
    left:0;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 100vh;
    overflow: initial;
    font-family: $default-font;
    

      &-overlay{
        position: absolute;
        top:0;
        left:0;
        width: 100vw;
        height: 100vh;
        background-color: rgb(0,0,0, .8);
        cursor: pointer;
      }

      &-box{
        position: relative;
        width: 80%;
        height: 80%;
        margin-top: 5%;
        margin-left:1%;
        margin-right: 1%;
        margin-bottom: 1%;
        padding: 50px;
        box-sizing: border-box;
        border-radius: 10px;
        background: get-color($theme,'white');
        cursor: auto;

        &-close-btn{
          position:absolute;
          top:20px;
          right: 20px;
          cursor: pointer;
          transition: transform 250ms ease-in-out;
          
          &:hover{
              transform: rotate(100deg);
          }
      }
  
      &-title{
          color: get-color($theme, 'gray');
          font-size: 30px;
      }
      
      &-content {
          margin-top:30px;
          font-size: 16px;
      }
    }


    @media srceen and (min-width:800px){
        .modal-box{
            max-width: 600px;
            margin: 0 30px;
        }
    }

}

.app-container {
    &-light {
      .modal-body {
        @include modal-body('light');
      }
    }
    &-dark {
      .modal-body {
        @include modal-body('dark');
      }
    }
  }