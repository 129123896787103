@import '../../styles/variables/breakpoints';
@import '../../styles/variables/animations';
@import '../../styles/shared/global';
@import '../../styles/variables/colors';

$spacing: 8px;

@mixin modal-menu($theme) {
  display: none;
  // background-color: get-color($theme, 'background');

  @include small-screen {
    display: block;
  }

  .menu-modal-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: get-color($theme, 'background');
  }

  .menu-modal-button {
    padding: .3rem;
    margin: .3rem;
    display: flex;
    flex: 1;

    background-color: get-color($theme, 'gray-' + $theme);
    
  }

  %menu-button-text {
    font-size: 1rem;
  }

  .menu-button-text {
    &-active {
      @extend %menu-button-text;
      color: get-color($theme, 'accent');
    }
    &-inactive {
      @extend %menu-button-text;
      color: get-color($theme, 'gray-bg');
    }
  }

  .modal-menu-close-button {
    width: 4rem;
    .modal-menu-close-icon {
      path:nth-child(1) {
        fill: get-color($theme, 'accent');
      }
    }
  }

  .menu-modal-button-content {    
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .menu-modal-row {
    // background-color: get-color($theme, 'background');
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-self: stretch;
  }
  .menu-modal-row-single {
    // display: flex;
    flex-direction: row;
  }

  .drawer-icons {
    &-active {
      path:nth-child(1) {
        fill: get-color($theme, 'accent');
      }
    }
    &-inactive {
      path:nth-child(1) {
        fill: get-color($theme, 'gray-bg');
      }
    }
  }
}

.modal-menu {
  &-light {
    @include modal-menu('light');
  }
  &-dark {
    @include modal-menu('dark');
  }
}

.modal-menu-open {
  animation: openModal 300ms;
}
.modal-menu-closed {
  animation: closeModal 300ms;
}

.drawer-spacing {
  min-height: 80px;
}

.drawer-nav-item {
  margin: 10px;
}
