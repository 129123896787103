@import '../variables/breakpoints';
@import '../shared/global';
@import '../variables/colors';

@mixin auth-container($theme) {
  display: flex;
  // min-height: 100vh;
  height: 100%;
  flex: 1;
  flex-direction: row;
  align-self: stretch;

  .auth-form-container {
    display: flex;
    flex: 1;
    width: 80%;
    align-items: center;
  }

  .auth-paper {
    @extend %auth-paper;
    background-color: get-color($theme, 'background');
    @if $theme == 'light' {
      box-shadow: 1px 1px 5px 1px get-color($theme, 'shadow');
    }

    p {
      text-align: center;
      color: get-color($theme, 'black');
    }
  }

  .auth-error {
    color: get-color($theme, 'error');
    font-family: 'Raleway', sans-serif;
    margin: 0.3rem;
    font-weight: bold;
  }

  .auth-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    // width: 100%;
  }

  .not-found-link {
    text-decoration: none;
    margin: 0.3rem;
    align-self: center;
    width: 100%;
    text-align: center;
    color: get-color($theme, 'accent');
    &:hover {
      color: get-color($theme, 'gray');
    }
  }

  .auth-navlink {
    @extend %auth-navlink;
    color: get-color($theme, 'accent');
    &:hover {
      color: get-color($theme, 'gray');
    }
  }

  .auth-panel {
    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    @include small-screen {
      padding: 0;
      width: 100%;
      align-items: center;
      position: absolute;
      height: 100vh;
      align-self: center;
      justify-content: center;
    }
    @include medium-screen {
      padding: 0;
      width: 100%;
      align-items: center;
      position: absolute;
      height: 100vh;
      align-self: center;
      justify-content: center;
    }
    @include large-screen {
      position: relative;
      flex: 3;
      background-color: get-color($theme, 'background');
    }
    @include extra-large-screen {
      flex: 2;
    }
  }

  .not-found-text {
    font-size: 2rem;
    color: get-color($theme, 'foreground');
    text-align: center;
  }
}

.app-container {
  &-light {
    .auth-container {
      @include auth-container('light');
    }
  }
  &-dark {
    .auth-container {
      @include auth-container('dark');
    }
  }
}

%auth-navlink {
  text-decoration: none;
  margin: 0.3rem;
  align-self: center;
  width: 100%;
  text-align: right;
}

%auth-paper {
  padding: 2rem;
  margin: 0.5rem;
  border-radius: 2px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.auth-logo {
  width: 100%;
  max-width: 300px;
  object-fit: fill;
  margin: 1rem;
  @include small-screen {
    width: 60%;
  }
  @include medium-screen {
    width: 60%;
  }
}

.auth-logo-container {
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
}

.auth-picture-container {
  display: flex;
  flex-direction: row;
  flex: 2;
  height: auto;
  @include small-screen {
    flex: 1;
    height: auto;
  }
  @include medium-screen {
    flex: 1;
    height: auto;
  }
  @include large-screen {
    flex: 3;
    height: auto;
  }
}

.auth-picture {
  width: 100%;
  height: 100%;
  // max-height: 100%;
  object-fit: cover;
  resize: both;
}

.auth-row {
  margin: 0.3rem;
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
}

.auth-settings-row {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  padding: 1rem;
  align-items: center;
}
