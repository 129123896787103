@import '../../styles/variables/breakpoints';
@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

@mixin feedlot-list-item($theme) {
  text-decoration: none;
  text-transform: uppercase;
  color: get-color($theme, 'foreground');
}

.feedlot-list-item {
  &-light {
    @include feedlot-list-item('light');
  }
  &-dark {
    @include feedlot-list-item('dark');
  }
}

@mixin feedlot-switcher($theme) {
  display: flex;
  flex-direction: row;
  flex: 1;

  .feedlot-switcher-button {
    .feedlot-switcher-button-text {
      color: get-color($theme, 'white');
      font-family: $default-font;
      text-align: center;
      font-weight: bold;
      font-size: larger;
      @include small-screen {
        font-size: 0.75rem;
      }
    }
  }

  .icon {
    path:nth-child(1) {
      fill: get-color($theme, 'white');
    }
  }
}

.app-container {
  &-light {
    .feedlot-switcher {
      @include feedlot-switcher('light');
    }
  }
  &-dark {
    .feedlot-switcher {
      @include feedlot-switcher('dark');
    }
  }
}
