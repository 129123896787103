@import '../../../styles/variables/fonts';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/breakpoints';

.card-traffic-light-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex: 1;

  @include small-screen {
    flex-direction: column;
  }

  // .traffic-red-light,
  // .traffic-yellow-light,
  // .traffic-green-light {
  //   background-color: darkblue;
  //   border: 1px solid black;
  //   padding: 2px;
  //   width: 100%;
  //   flex-grow: 1;
  //   // @include small-screen {
  //   //   min-width: 250px;
  //   // }
  //   // .MuiButton-root {
  //   //   background-color: salmon;
  //   //   @include small-screen {
  //   //     width: 150px;
  //   //   }
  //   //   @include medium-screen {
  //   //     width: 200px;
  //   //   }
  //   // }
  // }
}
