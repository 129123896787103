@import '../../../styles/variables/fonts';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/breakpoints';

@mixin card-traffic-light-container($theme) {
  .traffic-red-light,
  .traffic-yellow-light,
  .traffic-green-light {
    display: flex;
    // width: fit-content;
    width: 100%;
    height: 100%;
    // border: 1px solid black;
    padding: 2px;

    // .MuiBox-root {
    //   display: flex;
    //   align-items: center;
    //   min-height: 100px;
    //   width: auto;
    //   border: 1px solid #ccc;
    //   color: #ccc;
    //   background-color: $gray;
    //   border-radius: 5%;
    // }

    // .MuiButton-root {
    //   font-size: 1rem;
    //   font-family: $default-font;
    //   flex: 1;
    //   // flex-wrap: wrap;
    //   background-color: antiquewhite;
    //   .MuiButton-label {
    //     display: flex;
    //     flex-direction: column;
    //   }
    //   .MuiButton-label :nth-child(2) {
    //     margin-top: 5%;
    //     font-size: small;
    //   }
    // }
  }
  .traffic-red-light1,
  .traffic-yellow-light1,
  .traffic-green-light1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
    height: 140px;

    .MuiBox-root {
      display: flex;
      align-items: center;
      // min-height: 100px;
      // width: auto;
      border: 1px solid #ccc;
      color: #ccc;
      background-color: $gray;
      border-radius: 5%;
    }

    .MuiButton-root {
      flex: 1;
      font-size: 1rem;
      font-family: $default-font;
      // flex-wrap: wrap;
      .MuiButton-label {
        display: flex;
        flex-direction: column;
      }
      .MuiButton-label :nth-child(2) {
        margin-top: 5%;
        font-size: small;
      }
    }
  }
}

.app-container {
  &-light {
    .card-traffic-light-container {
      @include card-traffic-light-container('light');
    }
  }
  &-dark {
    .card-traffic-light-container {
      @include card-traffic-light-container('dark');
    }
  }
}
