@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import "../../../styles/shared/app";

@mixin table-animal($theme) {
  // display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  width: 99%;
  // background-color: rebeccapurple;

  .header-table {
    display: flex;
    flex-direction: row;
    // flex-direction: column;
    justify-content: end;
    // align-items: center;
    // height: 95px;
    overflow: hidden;
    // background-color: navy;
    // padding: 15px;
  }

  .subheader-table {
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 15px;
    // background-color: sienna;
  }
  .description-text {
    padding: 0px 15px;
    // height: 50px;
    font-family: $default-font;
    font-size: 1rem;
    text-align: center;
    color: get-color($theme, "gray");
    @include small-screen {
      font-size: 0.9rem;
    }
  }
  .sale-text {
    font-family: $default-font;
    color: get-color($theme, "accent");
  }

  .group-sales {
    display: flex;
    flex-direction: row;
    @include small-screen{
      margin-top: 10px;
    }
  }
  .border-group {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 5px get-color($theme, "shadow");
    border-radius: 10px;
  }
  .sticky {
    position: sticky;
    top: 60px;
    // background-image: -webkit-gradient(
    //   linear,
    //   left top,
    //   left bottom,
    //   from(rgba(248, 248, 255, 1)),
    //   to(rgba(248, 248, 255, 0.1)),
    //   color-stop(0.8, rgb(248, 248, 255))
    // );
    background-color: white;
    z-index: 2;
  }
  .table-animal-title-container-tabs {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    // background-color: aqua;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-transform: none;
    @include small-screen {
      flex-direction: column;
    }
  }

  .sales-tab {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    min-width: 10px;
    border: 1px solid get-color($theme, "border-gray");
    background-color: get-color($theme, "white");
    &:hover {
      background-color: get-color($theme, "white-smoke");
    }
  }

  .sales-tab.selected {
    color: get-color($theme, "accent");
    border: 3px solid get-color($theme, "accent");
    border-bottom: none;
    margin-bottom: -3px;
    z-index: 2;
  }

  .flex-container {
    display: flex;
  }

  .principal-sales-tab {
    max-width: 100%;
    position: sticky;
    left: 57px;
    z-index: 2;
    @include small-screen {
      left: 0px;
    }
  }

  .principal-sales-tab-indicator {
    display: none;
  }
}
.app-container {
  &-light {
    .table-animal {
      @include table-animal("light");
    }
  }
  &-dark {
    .table-animal {
      @include table-animal("dark");
    }
  }
}
