@import '../../styles/variables/breakpoints';
@import '../../styles/variables/colors';
@import '../../styles/shared/app';

@mixin home-body($theme) {
  display: flex;
  flex: 1;
  flex-direction: column;

  .home-row {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    align-self: stretch;
    @include large-screen {
      flex-direction: row;
    }
  }
}

.app-container {
  &-light {
    .home-body {
      @include home-body('light');
    }
  }
  &-dark {
    .home-body {
      @include home-body('dark');
    }
  }
}
