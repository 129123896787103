@import '../../styles/variables/breakpoints';
@import '../../styles/variables/colors';
@import '../../styles/shared/app';
@import '../../styles/variables/fonts';

@mixin animals-body($theme) {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  .animals-card {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 20px;
    box-shadow: 0px 0px 5px get-color($theme, 'shadow');
    border-radius: 10px;
    width: 100%;
    background-color: get-color($theme, 'white');
    padding: 20px 0px;
  }
  .animals-report-row {
    display: flex;
    flex: 1;    
    padding: 10px 0px;
    // margin: 20px;
    // justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    // align-self: center;
    align-items: center;
  }
  
  .animals-report-column {
    display: flex;
    flex-direction: column;
  }

  .group {
    display: flex;
    flex-direction: row;
    // flex: 1;
  }
  .group-value {
    display: flex;
    // flex: 1;
    flex-direction: column;
    align-items: center;
    margin: 0px 5px;
    max-width: 100px;
  }
  .group-select {
    margin: 0px 15px;
    display: flex;
    // flex: 1;
    align-items: center;
  }
  .text-select {
    padding-right: 15px;
    font-family: $default-font;
    font-size: 1.1rem;
    text-align: left;
    color: get-color($theme, 'gray-medium');
  }
  .text-select-item {
    font-family: $default-font;
    font-size: 1.1rem;
    color: get-color($theme, 'gray-medium');
  }
  .text-item {
    font-family: $default-font;
    font-size: 1rem;
    text-align: center;
    color: get-color($theme, 'gray-medium');
  }
  .text-value {
    font-family: $default-font;
    font-size: 1.5rem;
    color: get-color($theme, 'gray-medium');
  }
  .text-title {
    font-family: $default-font;
    font-size: 1.2rem;
    color: get-color($theme, 'accent');
  }

  .subheader-table {
    // margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // margin-top: 15px;
  }
  .description-text {
    // padding: 0px 15px;
    // height: 50px;
    font-family: $default-font;
    font-size: 1rem;
    text-align: center;
    color: get-color($theme, 'gray');
    @include small-screen {
      font-size: 0.9rem;
    }
  }
  .sale-text {
    font-family: $default-font;
    color: get-color($theme, 'accent');
  }

  .group-sales {
    display: flex;
    flex-direction: row;
    padding: 5px;
    @include small-screen {
      margin-top: 10px;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid get-color($theme, 'accent');
    border-radius: 10px; 

  }
}

.app-container {
  &-light {
    .animals-body {
      @include animals-body('light');
    }
  }
  &-dark {
    .animals-body {
      @include animals-body('dark');
    }
  }
}
