@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';

@mixin button(){
    margin-right: 4px;
    margin-left: 7px;
    width: 10px;
    height: 10px;
}

@mixin error($theme){

    display: flex;
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    padding: 1%;
    justify-content: space-around;
    // margin-left: 60px;

    &-type{
        display: flex;
        padding: 2px;
        &-label{
            
            &-required{
                display: none;
                &-active{
                    display: flex;
                }
            }

            &-numeric{
                display:none;
                &-active{
                    display:flex;
                }
            }

            &-date{
                display:none;
                &-active{
                    display:flex;
                }
            }

            &-breed{
                display:none;
                &-active{
                    display:flex;
                }
            }

            &-gender{
                display:none;
                &-active{
                    display:flex;
                }
            }

            &-ecc{
                display:none;
                &-active{
                    display:flex;
                }
            }
        }
        
        &-button{
            &-required{
                display: none;
                &-active{
                    display: flex;
                    @include button();
                    background-color: #ff000070;
                }
            }

            &-numeric{
                display:none;
                &-active{
                    display: flex;
                    @include button();
                    background-color: #008000;
                }
            }

            &-date{
                display:none;
                &-active{
                    display: flex;
                    @include button();
                    background-color: #00ffff;
                }
            }

            &-breed{
                display: none;
                &-active{
                    display: flex;
                    @include button();
                    background-color: #8a2be2;
                }
            }

            &-gender{
                display:none;
                &-active{
                    display:flex;
                    @include button();
                    background-color: #5f9ea0;
                }
            }

            &-ecc{
                display:none;
                &-active{
                    display: flex;
                    @include button();
                    background-color: #deb887;
                }
            }
        }           
    }
}

.app-container {
    &-light {
      .error {
        @include error('light');
      }
    }
    &-dark {
      .error {
        @include error('dark');
      }
    }
  }