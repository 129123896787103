.help-button-container {
  position: absolute;
  top: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  overflow: hidden;
}

.help-button {
  padding: 0px;
}
