@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';

@mixin access-frequency-body($theme) {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .chart-title-text {
        color: get-color($theme, "gray-medium");
        font-size: 1.1rem;
        z-index: 2;
        font-family: $default-font;
        text-align: center;
        margin-top: 2%;
      }

   .chart-responsive-container {
       padding: 5% 0 5% 0;
   }

  .tooltip-container {
    background-color: get-color($theme, 'ghost-white');
    border: 1px solid get-color($theme, 'gray');
    border-radius: 5px;
    padding: 5px;
  }
  .chart-value-text {
    font-weight: bold;
    font-size: 1rem;
    font-family: $default-font;
  }
}
.app-container {
  &-light {
    .access-frequency-body {
      @include access-frequency-body('light');
    }
  }
  &-dark {
    .access-frequency-body {
      @include access-frequency-body('dark');
    }
  }
}