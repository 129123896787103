@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../../styles/shared/app';

@mixin type-button($theme) {

  .card-type-button {
    width: auto;
    // min-height: 50px;
    margin: 0px 10px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px get-color($theme, 'shadow-light');
    background-color: get-color($theme, 'white');
  }

  .selected-card-type-button {
    width: auto;
    // min-height: 50px;
    margin: 0px 10px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: get-color($theme, 'very-pale-orange');
  }

  .type-button-text {
    text-transform: none;
    color: get-color($theme, 'gray');
    font-family: $default-font;
    font-size: 1rem;
    white-space: nowrap;
  }

  .selected-type-text {
    text-transform: none;
    font-size: 1rem;
    color: get-color($theme, 'accent-dark');
    font-family: $default-font;
    white-space: nowrap;
  }
}

.app-container {
  &-light {
    .type-button {
      @include type-button('light');
    }
  }
  &-dark {
    .type-button {
      @include type-button('dark');
    }
  }
}
