@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';

@mixin history($theme){
        
    width: max-content;
    font-family: $default-font;
    color: get-color($theme,'gray-medium');

    &-content{
      display: flex;
      text-align: center;
      justify-content: center;
      margin-bottom: 10px;

      &-buttons{
        
        justify-content: space-between;
        align-items: center;   

        
        &-style{
          border: none;
          background: none;
          width: 18px;
          color: get-color($theme,'gray-medium');

          &:hover{
            cursor: pointer;
          }

          &-warning{
            border: none;
            background: none;
            width: 18px;
            color: get-color($theme,'gray-medium');

            &:hover{
              cursor: default;
            }
          }

        }
      }
  
      &-text{
        margin-top: 3px;
        margin-left: 10px;

      }
    }


}
.app-container {
    &-light {
      .history {
        @include history('light');
      }
    }
    &-dark {
      .history {
        @include history('dark');
      }
    }
  }