@import '../../../../styles/variables/breakpoints';
@import '../../../../styles/variables/colors';
@import '../../../../styles/shared/app';

@mixin lot-params-body($theme) {
  // display: flex;
  // flex: 1;
  // flex-wrap: wrap;
  // width: 100%;
  // height: auto;

  #lots-select-fc {
    width: 8rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  .form-body {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    // width: 100%;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .input-margin-right {
      margin-right: 1rem;
    }
  }
}

.app-container {
  &-light {
    .lot-params-body {
      @include lot-params-body('light');
    }
  }
  &-dark {
    .lot-params-body {
      @include lot-params-body('dark');
    }
  }
}
