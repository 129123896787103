@import "../../../styles/variables/colors";
@import "../../../styles/variables/breakpoints";
@import "../../../styles/shared/app";

.arroba-price-box {
  position: absolute;
  min-width: 300px;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 24;
  padding: 10px;
  border-radius: 10px;
}

@mixin customer-slaughterhouses-body($theme) {
  display: flex;
  flex: 1;
  background-color: get-color($theme, "white-bg");
  flex-wrap: wrap;

  .params-row {
    width: 100%;

    .params-header {
      display: flex;
      flex: 1;

      h1 {
        width: 100%;
        padding: 1rem;
        color: get-color($theme, "gray-medium");
        font-size: 1.7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .form-body {
      margin: 1.5rem 1.5rem;
      justify-content: center;
      align-items: center;
      .personal-price-switch {
        span {
          color: get-color($theme, "accent");
        }
      }
    }
  }
}

.app-container {
  &-light {
    .customer-slaughterhouses-body {
      @include customer-slaughterhouses-body("light");
    }
  }
  &-dark {
    .customer-slaughterhouses-body {
      @include customer-slaughterhouses-body("dark");
    }
  }
}
