@import '../../styles/variables/breakpoints';
@import '../../styles/variables/animations';
@import '../../styles/shared/global';
@import '../../styles/variables/colors';

$spacing: 8px;

@mixin drawer($theme) {
  flex-shrink: 0;
  z-index: 3;
  white-space: nowrap;

  .drawer-icons {
    &-active {
      path:nth-child(2) {
        fill: get-color($theme, 'accent');
      }
    }
    &-inactive {
      path:nth-child(2) {
        fill: get-color($theme, 'gray');
      }
    }
  }
}

.app-container {
  &-light {
    .drawer {
      @include drawer('light');
    }
  }
  &-dark {
    .drawer {
      @include drawer('dark');
    }
  }
}

.drawer-spacing {
  min-height: 80px;
}

.drawer-opened {
  width: $sidebar-open-width;
  animation: openSidebar 300ms;
  z-index: 0;
}

.drawer-closed {
  animation: closeSidebar 300ms;
  overflow-x: hidden;
  width: $spacing * 7;
  z-index: 0;
}

.drawer-nav-item {
  margin: 10px;
}
