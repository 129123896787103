@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../../styles/shared/app';

@mixin custom-table($theme) {
  span {
    color: inherit;
  }
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }

  .download-csv {
    display: flex;
    color: get-color($theme, 'gray');
    // background-color: #922700;
    align-items: center;
    border-radius: 5px;
    border: 1px solid get-color($theme, 'border-gray');
    padding: 2px;
    margin: 5px;
  }
  .download-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0px 15px;
  }
  .table-cell {
    color: get-color($theme, 'gray-medium');
    padding: 8px;
    font-size: 0.9rem;
    min-width: 50px;
    @include small-screen {
      font-size: 0.8rem;
    }
  }
  .collapse-content {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 0.95rem;
    font-weight: bolder;
    // color: get-color($theme, 'gray-medium');
  }
  .button-collapse {
    padding: 8px;
    cursor: pointer;
    font-size: 0.87rem;
    // box-shadow: inset 0px 0px 5px get-color($theme, 'shadow');
    background-color: get-color($theme, 'white');
    border: 1px solid get-color($theme, 'border-gray');
    &:hover {
      background-color: get-color($theme, 'ghost-white');
      // box-shadow: inset 0px 0px 5px get-color($theme, 'shadow');
    }
    @include small-screen {
      font-size: 0.7rem;
    }
  }

  .button-collapse-real {
    padding: 8px;
    cursor: pointer;
    // box-shadow: inset 0px 0px 5px get-color($theme, 'shadow');
    background-color: rgba(#922700, 0.1);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: get-color($theme, 'red-dark');
    &:hover {
      background-color: rgba(#922700, 0.08);
      // box-shadow: inset 0px 0px 5px get-color($theme, 'shadow');
    }
    @include small-screen {
      font-size: 0.7rem;
    }
  }

  .button-collapse-sales {
    padding: 8px;
    cursor: pointer;
    // box-shadow: inset 0px 0px 5px get-color($theme, 'shadow');
    background-color: rgba(#ff6400, 0.1);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: get-color($theme, 'accent');
    &:hover {
      background-color: rgba(#ff6400, 0.08);
      // box-shadow: inset 0px 0px 5px get-color($theme, 'shadow');
    }
    @include small-screen {
      font-size: 0.7rem;
    }
  }
  .table-cell-head {
    padding: 5px 10px;
    // white-space: nowrap;
    color: get-color($theme, 'gray');
    font-weight: bolder;
    vertical-align: top;
  }
  .icon-button-head {
    display: flex;
    justify-content: center;
  }
  .chip-head {
    min-width: 60px;
  }
  .content-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
  }
  .icon-button {
    path:nth-child(1) {
      fill: get-color($theme, 'gray');
    }
  }
  .icon-button-collapse {
    path:nth-child(1) {
      fill: get-color($theme, 'gray-medium');
    }
  }

  .icon-button-collapse-sales {
    path:nth-child(1) {
      fill: get-color($theme, 'accent');
    }
  }
  .icon-button-collapse-real {
    path:nth-child(1) {
      fill: get-color($theme, 'red-dark');
    }
  }
  .table-container {
    // max-height: calc(100vh - 335px);
    width: 100%;
    @include large-screen {
      // max-height: calc(100vh - 235px);
    }
  }
}
.app-container {
  &-light {
    .custom-table {
      @include custom-table('light');
    }
  }
  &-dark {
    .custom-table {
      @include custom-table('dark');
    }
  }
}
