@import '../variables/colors';
@import '../variables/breakpoints';
@import '../variables/fonts';

$icon-container-width: 100px;
$icon-container-height: 80px;

@mixin card-container($theme) {
  position: relative;
  display: flex;
  flex: 1;
  margin: 1rem;
  margin-top: 25px;
  border-radius: 10px;
  z-index: 0;
  padding: 20px;
  min-width: 280px;
  box-shadow: 0px 0px 5px get-color($theme, 'shadow');
  background-color: get-color($theme, 'background-card');
  text-transform: none;
  @include large-screen {
    min-width: 510px;
  }

  .info-button {
    height: 50px;
    width: 200px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px get-color($theme, 'shadow');
    text-transform: none;
  }

  .text-info-button {
    color: get-color($theme, 'accent-light');
  }

  .card-icon {
    path:nth-child(1) {
      fill: get-color($theme, 'accent');
    }
  }

  .card-body {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .card-content {
    display: flex;
    flex: 1;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    margin: 0px;
    padding-top: 10px;
  }

  .sales-buttons-space {
    height: 20px;
  }

  .empty-card-body {
    display: flex;
    min-height: 150px;
    align-items: center;
    justify-content: center;
    text {
      color: get-color($theme, 'foreground');
      font-family: $default-font;
    }
  }

  .card-chart-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    min-height: 150px;
    margin-top: 1rem;
  }

  .zootechnical-chart-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    min-height: 225px;
    width: 85%;
    margin-top: 1rem;
  }

  .card-icon-container {
    position: absolute;
    top: 20px;
    left: 20px;
    box-shadow: 0px 0px 5px get-color($theme, 'shadow');
    transform: translateY(-50%);
    width: $icon-container-width;
    height: $icon-container-height;
    border-radius: 5px;
    background-color: get-color($theme, 'white');
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .card-footer {
    display: flex;
    min-height: 1.5rem;
    align-self: stretch;
    justify-content: center;
    &-left {
      @extend .card-footer;
      justify-content: left;
    }
  }

  .card-footer-text {
    font-family: $default-font;
    font-size: 1rem;
    text-transform: none;
    color: get-color($theme, 'gray-medium');
    padding: 0 3px;
    &-left {
      @extend .card-footer-text;
      text-align: left;
      padding-left: 0.5rem;
    }
  }

  .profit-footer {
    display: flex;
    margin-left: 30px;
    min-height: 1.5rem;
    align-items: flex-end;
    align-self: stretch;
    justify-content: space-between;
    @include small-screen {
      margin-left: 0px;
      flex-direction: column;
    }
  }

  .zootechnical-footer {
    display: flex;
    margin-left: 30px;
    min-height: 1.5rem;
    align-items: flex-end;
    align-self: stretch;
    justify-content: flex-end;
    @include small-screen {
      margin-left: 0px;
      flex-direction: column;
    }
  }

  .card-profit-footer {
    align-self: flex-end;
    font-family: $default-font;
    text-transform: none;
    align-self: center;
    word-wrap: normal;
    font-size: 0.9rem;
    color: get-color($theme, 'gray-medium');
    text-align: left;
    @include small-screen {
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .chart-value-text {
    font-size: 1rem;
    font-family: $default-font;
  }
  .label-chart {
    fill: get-color($theme, 'red-dark');
  }
  .label-xlim {
    fill: get-color($theme, 'gray-medium');
  }

  .chart-tick-text {
    font-family: $default-font;
    fill: get-color($theme, 'white');
    text-transform: capitalize;
    font-size: 1rem;
    z-index: 2;
  }

  .card-title-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    @include large-screen {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .card-title-vertical-line {
    display: flex;
    height: 60%;
    align-content: center;
    justify-content: right;
    border: 0;
    border-left: 2px solid get-color($theme, 'gray-light');
    margin-top: 1rem;
    padding: 0;
  }

  .card-title {
    font-family: $default-font;
    font-size: 1.8rem;
    text-align: left;
    padding-top: 12px;
    padding-left: $icon-container-width + 20px;
    color: get-color($theme, 'gray');
    @include small-screen {
      font-size: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card-title-options {
    margin-right: 0px;
    align-self: center;
    display: flex;
    flex-direction: row;
    @include large-screen {
      margin-right: 40px;
      // margin-right: 30px;
    }
    // display: flex;
    // flex: 0;
    // align-items: center;
    margin-right: 0px;
    // // padding-top: 0.8rem;
    // // padding-left: 0.6rem;
    // justify-content: flex-end;
  }

  .card-title-small {
    margin-top: 0px;
    font-family: $default-font;
    font-size: 1rem;
    text-align: center;
    color: get-color($theme, 'white');
  }

  .chart-responsive-container {
    position: absolute;
    left: 0;
    right: 0;
  }

  .separator {
    display: flex;
    flex: 1;
    flex-direction: row;
    border-bottom: 1px solid get-color($theme, 'foreground');
    margin: 0;
    padding: 0;
    transform: translateY(-10px);
  }

  .legend {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 20px 0px;
  }
  .group-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    margin: 0px 20px;
  }
  .color-legend {
    width: 15px;
    height: 15px;
    margin: 0px 5px;
  }
  .text-legend {
    display: flex;
    height: 15px;
    align-items: flex-end;
    font-family: $default-font;
    font-size: 1rem;
    color: get-color($theme, 'gray');
  }
  .card-sales-buttons-line {
    display: flex;
    flex-direction: row;
    margin-right: 40px;
    padding: 5px;
    // margin-bottom: 20px;
    align-items: center;
    align-self: flex-end;
    @include small-screen {
      margin-right: 0px;
    }
  }
}

.app-container {
  &-light {
    .card-container {
      @include card-container('light');
    }
  }
  &-dark {
    .card-container {
      @include card-container('dark');
    }
  }
}
