.hidden {
  display: none;
}

.theme-select-container {
  display: flex;
  align-self: stretch;
  align-items: center;
  border-width: 1px ;
  padding-right: 1rem;
  padding-left: 1rem;
}

.inactive {
  color: #FFF;
  background-color: #c0392b;
}