@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../../styles/shared/app';

@mixin custom-select-multiple($theme) {
  .select-root {
    // color: get-color($theme, 'gray');
    border: 1px solid get-color($theme, 'gray');
    border-radius: 5px;
    max-width: 200px;
    text-align: left;
    padding-left: 5px;
    margin: 5px;
    font-size: 1rem;
    @include small-screen {
      font-size: 0.9rem;
    }
  }
}
.app-container {
  &-light {
    .custom-select-multiple {
      @include custom-select-multiple('light');
    }
  }
  &-dark {
    .custom-select-multiple {
      @include custom-select-multiple('dark');
    }
  }
}
