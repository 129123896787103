@mixin lot-weighings-body($theme) {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  .input-margin {
    margin: 1rem;
  }

  .toolbar {
    display: flex;
    padding: 0px 10px;
    align-items: baseline;
  }
}

.app-container {
  &-light {
    .lot-weighings-body {
      @include lot-weighings-body('light');
    }
  }
  &-dark {
    .lot-weighings-body {
      @include lot-weighings-body('dark');
    }
  }
}
