@import '../../../../styles/variables/breakpoints';
@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/fonts';
@import '../../../../styles/shared/app';

@mixin lot-table-details($theme) {
  // display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
  width: 99%;
  // background-color: royalblue;
  .rotate-text {
    writing-mode: vertical-rl;
  }
  .description-text {
    padding: 0px 15px;
    // height: 50px;
    font-size: 1rem;
    text-align: center;
    color: get-color($theme, 'gray-medium');
    @include small-screen {
      font-size: 0.9rem;
    }
  }
  .sticky {
    position: sticky;
    top: 60px;
    // background-image: -webkit-gradient(
    //   linear,
    //   left top,
    //   left bottom,
    //   from(rgba(248, 248, 255, 1)),
    //   to(rgba(248, 248, 255, 0.1)),
    //   color-stop(0.8, rgb(248, 248, 255))
    // );
    background-color: white;
    z-index: 2;
  }

  .sales-tab {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    min-width: 10px;
    border: 1px solid get-color($theme, 'border-gray');
    background-color: get-color($theme, 'white');
    &:hover {
      background-color: get-color($theme, 'white-smoke');
    }
  }

  .sales-tab.selected {
    color: get-color($theme, 'accent');
    border: 3px solid get-color($theme, 'accent');
    border-bottom: none;
    margin-bottom: -3px;
    z-index: 2;
  }

  .flex-container {
    display: flex;
  }

  .principal-sales-tab {
    max-width: 100%;
    position: sticky;
    left: 57px;
    z-index: 2;
    @include small-screen {
      left: 0px;
    }
  }

  .principal-sales-tab-indicator {
    display: none;
  }

  .header-button-selected {
    color: get-color($theme, 'accent');
    font-weight: bolder;
    border: 1px solid get-color($theme, 'white');
    background-color: rgba(#ff6400, 0.05);
  }

  .background-traditional {
    background-color: rgba(#939597, 0.05);
  }
  .background-current {
    background-color: rgba(#922700, 0.05);
  }
  .background-beeftrader {
    background-color: rgba(#ff6400, 0.05);
  }
  .text-traditional {
    color: get-color($theme, 'gray');
    font-weight: bolder;
    border: 1px solid get-color($theme, 'white');
  }

  .text-current {
    color: get-color($theme, 'red-dark');
    font-weight: bolder;
    border: 1px solid get-color($theme, 'white');
  }
  .table-description-value {
    color: get-color($theme, 'gray');
    border: 1px solid get-color($theme, 'white');
    background-color: rgba(#f8f8f8, 0.7);
    padding: 0px 10px;
  }
  .table-values {
    color: get-color($theme, 'gray-medium');
    border: 1px solid get-color($theme, 'white');
    padding: 4px;
  }
}
.app-container {
  &-light {
    .lot-table-details {
      @include lot-table-details('light');
    }
  }
  &-dark {
    .lot-table-details {
      @include lot-table-details('dark');
    }
  }
}
