@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../../styles/shared/app';
  
@mixin sales-button($theme) {
  padding: 0px 5px;
  .card-sales-button{
    width: 80px;
    min-height: 30px;
    margin: 0px 4px;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0px 0px 5px get-color($theme, "shadow-light");
  }
  .selected-card-sales-button {
    width: 80px;
    min-height: 30px;
    margin: 0px 4px;
    padding: 0;
    border-radius: 5px;
    background-color: get-color($theme, "very-pale-orange");
  }


  .sales-button-text {
    text-transform: none;
    color: get-color($theme, "gray");
    font-family: $default-font;
  }

  .selected-sales-text {
    text-transform: none;
    color: get-color($theme, "accent-dark");
    font-family: $default-font;
  }

}

.app-container {
  &-light {
    .sales-button {
      @include sales-button("light");
    }
  }
  &-dark {
    .sales-button {
      @include sales-button("dark");
    }
  }
}
