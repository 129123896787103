@import "../../../styles/variables/colors";
@import "../../../styles/variables/breakpoints";
@import "../../../styles/shared/app";

@mixin region-price-slaughterhouses-body($theme) {
  display: flex;
  flex: 1;
  background-color: get-color($theme, "white-bg");
  flex-wrap: wrap;

  .params-row {
    width: 100%;

    .params-header {
      display: flex;
      flex: 1;

      h1 {
        width: 100%;
        padding: 1rem;
        color: get-color($theme, "gray-medium");
        font-size: 1.7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .form-body {
      margin: 1.5rem 1.5rem;
      justify-content: center;
      align-items: center;
      .personal-price-switch {
        span {
          color: get-color($theme, "accent");
        }
      }
    }
  }
}

.app-container {
  &-light {
    .region-price-slaughterhouses-body {
      @include region-price-slaughterhouses-body("light");
    }
  }
  &-dark {
    .region-price-slaughterhouses-body {
      @include region-price-slaughterhouses-body("dark");
    }
  }
}
